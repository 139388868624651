import * as Sentry from '@sentry/react';

interface LogContext {
  component?: string;
  action?: string;
  userId?: string;
  phoneIdAlias?: string;
  additionalData?: Record<string, any>;
}

export class LoggingService {
  static logError(error: Error, context: LogContext) {
    // Formatear el mensaje de error para la consola
    const timestamp = new Date().toISOString();
    const errorDetails = {
      timestamp,
      level: 'ERROR',
      component: context.component ?? 'UNKNOWN',
      action: context.action ?? 'UNKNOWN',
      message: error.message,
      stack: error.stack,
      ...context
    };

    // Log local para desarrollo
    if (process.env.NODE_ENV === 'development') {
      console.error(
        `[${timestamp}] 🔴 ERROR en ${context.component}:\n`,
        `Acción: ${context.action}\n`,
        `Mensaje: ${error.message}\n`,
        'Contexto:', context,
        '\nStack:', error.stack
      );
    }

    // Enviar a Sentry con contexto enriquecido
    Sentry.withScope((scope) => {
      scope.setLevel('error');
      scope.setContext('error_details', errorDetails);
      scope.setContext('user_context', {
        userId: context.userId,
        phoneIdAlias: context.phoneIdAlias
      });
      scope.setContext('technical_context', {
        userAgent: navigator.userAgent,
        timestamp,
        environment: process.env.NODE_ENV
      });
      Sentry.captureException(error);
    });

    return errorDetails;
  }


static logInfo(message: string, context: LogContext) {
    const timestamp = new Date().toISOString();
    const logDetails = {
      timestamp,
      level: 'INFO',
      component: context.component ?? 'UNKNOWN',
      action: context.action ?? 'UNKNOWN',
      message,
      ...context
    };

    if (process.env.NODE_ENV === 'development') {
      console.log(
        `[${timestamp}] 🔵 INFO en ${context.component}:`,
        `\nAcción: ${context.action}`,
        `\nMensaje: ${message}`,
        '\nContexto:', context
      );
    }

    // Enviar info logs a Sentry
    Sentry.withScope((scope) => {
      scope.setLevel('info');  // Nivel de información
      scope.setContext('info_details', logDetails);
      Sentry.captureMessage(message);
    });

    return logDetails;
  }
  static logWarning(message: string, context: LogContext) {
    const timestamp = new Date().toISOString();
    const logDetails = {
      timestamp,
      level: 'WARNING',
      component: context.component ?? 'UNKNOWN',
      action: context.action ?? 'UNKNOWN',
      message,
      ...context
    };

    if (process.env.NODE_ENV === 'development') {
      console.warn(
        `[${timestamp}] 🟡 WARNING en ${context.component}:`,
        `\nAcción: ${context.action}`,
        `\nMensaje: ${message}`,
        '\nContexto:', context
      );
    }

    // También enviamos warnings a Sentry pero con nivel warning
    Sentry.withScope((scope) => {
      scope.setLevel('warning');
      scope.setContext('warning_details', logDetails);
      Sentry.captureMessage(message);
    });

    return logDetails;
  }
}
